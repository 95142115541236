import { Container } from '../shared/StyledComponents';
import { FooterWrapper } from './Footer.styles';

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <p>&copy; {new Date().getFullYear()} Brent Purks. All rights reserved.</p>
      </Container>
    </FooterWrapper>
  );
};

export default Footer; 