import styled from 'styled-components';

export const ContactSection = styled.section`
  background: ${({ theme }) => theme.colors.primary};
`;

export const ContactForm = styled.form`
  max-width: 600px;
  margin: 0 auto 3rem;

  .form-group {
    margin-bottom: 1.5rem;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  input, textarea {
    width: 100%;
    padding: 0.8rem;
    background: ${({ theme }) => theme.colors.secondary};
    border: 1px solid ${({ theme }) => theme.colors.textSecondary}40;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.text};
    font-family: inherit;
    
    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.accent};
    }
  }

  button {
    width: 100%;
    padding: 1rem;
    background: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    font-weight: 600;
    transition: ${({ theme }) => theme.transitions.standard};
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 0 15px ${({ theme }) => theme.colors.accent}40;
    }
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;

  a {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: 1.5rem;
    transition: ${({ theme }) => theme.transitions.standard};

    &:hover {
      color: ${({ theme }) => theme.colors.accent};
      transform: translateY(-2px);
    }
  }
`; 