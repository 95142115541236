import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  background: ${({ theme }) => theme.colors.secondary};
  padding: 2rem 0;
  text-align: center;
  
  p {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`; 