import styled from 'styled-components';

export const ProjectsSection = styled.section`
  background: ${({ theme }) => theme.colors.primary};
`;

export const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ProjectCard = styled.div`
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;
  overflow: hidden;
  transition: ${({ theme }) => theme.transitions.standard};
  position: relative;
  display: flex;
  flex-direction: column;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .project-image {
    height: 200px;
    background: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    &:hover img {
      transform: scale(1.05);
    }
    
    span {
      color: ${({ theme }) => theme.colors.textSecondary};
    }
  }

  .project-content {
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    .title-container {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0.5rem;

      h3 {
        margin-bottom: 0;
        color: ${({ theme }) => theme.colors.text};
      }

      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
    }

    h3 {
      margin-bottom: 0.5rem;
      color: ${({ theme }) => theme.colors.text};
    }

    p {
      color: ${({ theme }) => theme.colors.textSecondary};
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }

    .tech-stack {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-bottom: 1.5rem;

      span {
        font-size: 0.8rem;
        padding: 0.25rem 0.75rem;
        background: ${({ theme }) => theme.colors.primary};
        border-radius: 15px;
        color: ${({ theme }) => theme.colors.accent};
      }
    }

    .bottom-content {
      margin-top: auto;
      padding-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .project-links {
        display: flex;
        gap: 1rem;

        a, .coming-soon {
          font-size: 0.9rem;
        }
        
        a:hover {
          text-decoration: underline;
        }

        .coming-soon {
          color: ${({ theme }) => theme.colors.textSecondary};
          font-style: italic;
        }
      }

      .corner-icon {
        position: static;
        width: 40px;
        height: 40px;
        opacity: 0.8;
        transition: ${({ theme }) => theme.transitions.standard};
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &:hover {
          opacity: 1;
          transform: scale(1.1);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
`;

export const FilterButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;

  button {
    padding: 0.5rem 1.5rem;
    border: 1px solid ${({ theme }) => theme.colors.accent};
    border-radius: 25px;
    color: ${({ theme }) => theme.colors.text};
    background: transparent;
    transition: ${({ theme }) => theme.transitions.standard};
    font-size: 0.9rem;

    &:hover {
      background: ${({ theme }) => theme.colors.accent}20;
    }

    &.active {
      background: ${({ theme }) => theme.colors.accent};
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    
    button {
      width: 200px;
    }
  }
`; 