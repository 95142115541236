import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Container, SectionTitle } from '../shared/StyledComponents';
import { 
  ProjectsSection, 
  ProjectGrid, 
  ProjectCard,
  FilterButtons 
} from './Projects.styles';

const projectsData = [
  {
    title: "Giigs",
    description: "A mobile application connecting musicians with hiring venues for events. Built with React Native and integrated with RevenueCat for in-app purchases.",
    tech: ["React Native", "Expo", "Redux", "RevenueCat"],
    image: "placeholder-1.jpg",
    video: "giigsVideo.mp4",
    logo: "giigsLogo.png",
    icon: "giigsIcon.png",
    type: "mobile",
    links: {
      site: "https://www.giigsapp.com"
    }
  },
  {
    title: "Secured Chat",
    description: "An innovative mobile app integrating with Llama LLM, enabling users to generate AI-driven fake conversations under duress situations. (In Development - Expected Release: June 1, 2024)",
    tech: ["React Native", "Expo", "AI Integration", "WebSocket"],
    image: "securedChatIcon.png",
    icon: "securedChatIcon.png",
    type: "mobile",
    links: {
      site: null
    }
  },
  {
    title: "Life Wireless Mobile App",
    description: "Full-stack development of a telecommunications app featuring eSIM integration, user authentication, and real-time usage tracking. Implemented secure payment processing and account management features.",
    tech: ["React Native", "Expo"],
    image: "placeholder-3.jpg",
    icon: "lwIcon.png",
    type: "mobile",
    links: {
      site: "https://www.lifewireless.com"
    }
  },
  {
    title: "Life Wireless Account Portal",
    description: "Contributed to the customer account portal development, implementing responsive designs and usage monitoring features. Built interactive dashboard components and enhanced the overall user experience.",
    tech: ["Vue.js", "Laravel", "REST API"],
    image: "lwApSC.png",
    type: "web",
    links: {
      site: "https://www.lifewireless.com/account"
    }
  },
  {
    title: "Life Wireless Website",
    description: "Developed key frontend features for customer onboarding and plan selection. Created interactive components for service activation and implemented responsive design patterns.",
    tech: ["Vue.js", "Laravel", "REST API"],
    image: "lwSC.png",
    type: "web",
    links: {
      site: "https://www.lifewireless.com"
    }
  },
  {
    title: "PureTalk",
    description: "Collaborated on frontend features for the customer portal and main website. Built responsive components for account management and implemented real-time usage monitoring interfaces.",
    tech: ["Vue.js", "Laravel", "REST API"],
    image: "ptSC.png",
    type: "web",
    links: {
      site: "https://www.puretalk.com"
    }
  },
  {
    title: "H2O Wireless",
    description: "Contributed to frontend development, implementing responsive components for plan selection and account management. Enhanced user interface with interactive features and real-time service status updates.",
    tech: ["Vue.js", "Laravel", "REST API"],
    image: "h2oSC.png",
    type: "web",
    links: {
      site: "https://www.h2owireless.com"
    }
  },
  {
    title: "PureTalk Business Portal",
    description: "Developed frontend features for managing multiple business lines and corporate accounts. Implemented advanced reporting tools and bulk management capabilities.",
    tech: ["Vue.js", "Laravel", "REST API"],
    image: "ptbSC.png",
    type: "web",
    links: {
      site: "https://www.puretalk.com/business"
    }
  }
];

const Projects = () => {
  const [filter, setFilter] = useState('all');
  
  const filteredProjects = projectsData.filter(project => {
    if (filter === 'all') return true;
    return project.type === filter;
  });

  return (
    <ProjectsSection id="projects">
      <Container>
        <SectionTitle
          as={motion.h2}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          My Work
        </SectionTitle>
        <FilterButtons>
          <button 
            className={filter === 'all' ? 'active' : ''} 
            onClick={() => setFilter('all')}
          >
            All Projects
          </button>
          <button 
            className={filter === 'mobile' ? 'active' : ''} 
            onClick={() => setFilter('mobile')}
          >
            Mobile Apps
          </button>
          <button 
            className={filter === 'web' ? 'active' : ''} 
            onClick={() => setFilter('web')}
          >
            Web Apps
          </button>
        </FilterButtons>
        <ProjectGrid>
          <AnimatePresence mode="wait">
            {filteredProjects.map((project, index) => (
              <ProjectCard
                key={project.title}
                as={motion.div}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="project-image">
                  {project.video ? (
                    <video autoPlay muted loop playsInline>
                      <source src={project.video} type="video/mp4" />
                    </video>
                  ) : project.image ? (
                    <img src={project.image} alt={`${project.title} screenshot`} />
                  ) : (
                    <span>Project Preview</span>
                  )}
                </div>
                <div className="project-content">
                  <div className="title-container">
                    <h3>{project.title}</h3>
                    {project.logo && <img src={project.logo} alt={`${project.title} logo`} />}
                  </div>
                  <p>{project.description}</p>
                  <div className="tech-stack">
                    {project.tech.map((tech, i) => (
                      <span key={i}>{tech}</span>
                    ))}
                  </div>
                  <div className="bottom-content">
                    <div className="project-links">
                      {project.links.site ? (
                        <a href={project.links.site} target="_blank" rel="noopener noreferrer">
                          Visit Site
                        </a>
                      ) : (
                        <span className="coming-soon">Coming Soon</span>
                      )}
                    </div>
                    {project.icon && (
                      <div className="corner-icon">
                        <img src={project.icon} alt={`${project.title} icon`} />
                      </div>
                    )}
                  </div>
                </div>
              </ProjectCard>
            ))}
          </AnimatePresence>
        </ProjectGrid>
      </Container>
    </ProjectsSection>
  );
};

export default Projects; 