import { motion } from 'framer-motion';
import { Container, SectionTitle } from '../shared/StyledComponents';
import { 
  AboutSection, 
  AboutContent, 
  AboutGrid, 
  AboutCard,
  ExperienceCounter 
} from './About.styles';

const About = () => {
  return (
    <AboutSection id="about">
      <Container>
        <SectionTitle
          as={motion.h2}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          About Me
        </SectionTitle>
        <AboutGrid>
          <AboutContent>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <p>
                I'm an experienced software developer with <strong>5 years</strong> of expertise in mobile app development
                and scalable full-stack solutions. Currently working at <strong>Telrite Holdings</strong> as a Frontend Web & Mobile Developer,
                I specialize in creating high-performance, user-focused applications using React Native, Expo, and modern backend technologies.
              </p>
              <p>
                My passion lies in innovation and creating impactful digital experiences. From developing telecommunications
                apps to co-founding <strong>BCB Labs L.L.C</strong>, I've consistently delivered solutions that combine technical excellence
                with practical business value.
              </p>
            </motion.div>
          </AboutContent>
          
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <AboutCard>
              <h3>Quick Facts</h3>
              <ul>
                <motion.li
                  whileHover={{ x: 10 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <span>🚀</span> Specialized in React Native & Mobile Development
                </motion.li>
                <motion.li
                  whileHover={{ x: 10 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <span>💼</span> Working at Telrite Holdings
                </motion.li>
                <motion.li
                  whileHover={{ x: 10 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <span>🌟</span> Co-founder of BCB Labs L.L.C
                </motion.li>
                <motion.li
                  whileHover={{ x: 10 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <span>📱</span> Developed multiple telecom apps
                </motion.li>
              </ul>
            </AboutCard>
          </motion.div>
        </AboutGrid>

        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <ExperienceCounter>
            <div className="counter-item">
              <h3>5+</h3>
              <p>Years of Experience</p>
            </div>
            <div className="counter-item">
              <div className="project-counts">
                <h3>8</h3>
                <span className="plus-upcoming">+3</span>
              </div>
              <p>Projects in Production</p>
              <span className="upcoming-note">3 More Coming Soon</span>
            </div>
            <div className="counter-item">
              <h3>7.5M+</h3>
              <p>Platform Users</p>
            </div>
          </ExperienceCounter>
        </motion.div>
      </Container>
    </AboutSection>
  );
};

export default About; 