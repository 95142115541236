import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

export const Button = styled(motion.button)`
  background: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.primary};
  padding: 0.8rem 2rem;
  border-radius: 4px;
  font-weight: 600;
  transition: ${({ theme }) => theme.transitions.standard};
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 15px ${({ theme }) => theme.colors.accent}40;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 3rem;
  }
`; 