import { motion } from 'framer-motion';
import { Container } from '../shared/StyledComponents';
import { HeroSection, HeroContent, HeroImage, CTAButton, SocialLinks } from './Hero.styles';
import { Link } from 'react-scroll';
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa';

const Hero = () => {
  return (
    <HeroSection id="home">
      <Container>
        <HeroContent>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1>
              <motion.span
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 }}
              >
                Hi, I'm
              </motion.span>{' '}
              <motion.span
                className="highlight"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5 }}
              >
                Brent Purks
              </motion.span>
            </h1>
            <motion.p
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.7 }}
            >
              Crafting innovative mobile and web experiences
            </motion.p>
            <motion.p
              className="subtitle"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.9 }}
            >
              Full Stack Developer | Mobile App Specialist | React Native Expert
            </motion.p>
            <SocialLinks>
              <motion.a
                href="mailto:brentpurkswork@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ y: -3 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.1 }}
              >
                <FaEnvelope />
              </motion.a>
              <motion.a
                href="https://github.com/ziggyboy2001"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ y: -3 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.3 }}
              >
                <FaGithub />
              </motion.a>
              <motion.a
                href="https://www.linkedin.com/in/brent-purks"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ y: -3 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.5 }}
              >
                <FaLinkedin />
              </motion.a>
            </SocialLinks>
            <Link to="projects" smooth={true} duration={500}>
              <CTAButton
                as={motion.button}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.7 }}
              >
                View My Work
              </CTAButton>
            </Link>
          </motion.div>
        </HeroContent>
        <HeroImage
          as={motion.div}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <img src="profile.jpg" alt="Brent Purks" />
        </HeroImage>
      </Container>
    </HeroSection>
  );
};

export default Hero; 