import styled from 'styled-components';

export const SkillsSection = styled.section`
  background: ${({ theme }) => theme.colors.secondary};
`;

export const SkillsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  max-width: 1000px;
  margin: 3rem auto 0;
  
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const SkillCategory = styled.div`
  h4 {
    color: ${({ theme }) => theme.colors.accent};
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }
`;

export const SkillsList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${({ theme }) => theme.colors.accent};
    
    svg {
      font-size: 1.2rem;
    }

    span {
      color: ${({ theme }) => theme.colors.text};
      font-size: 0.9rem;
    }
  }
`; 