import styled from 'styled-components';
import { Container } from '../shared/StyledComponents';

export const AboutSection = styled.section`
  background: ${({ theme }) => theme.colors.secondary};
  padding: 4rem 0;
`;

export const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 4rem;
  margin-bottom: 3rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

export const AboutContent = styled.div`
  p {
    font-size: 1.1rem;
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-bottom: 1.5rem;
    line-height: 1.6;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

export const AboutCard = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  height: 100%;

  h3 {
    color: ${({ theme }) => theme.colors.accent};
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
  }

  ul {
    list-style: none;
    
    li {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      color: ${({ theme }) => theme.colors.text};
      cursor: default;

      span {
        margin-right: 1rem;
        font-size: 1.2rem;
      }
    }
  }
`;

export const ExperienceCounter = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  text-align: center;
  margin-top: 4rem;

  .counter-item {
    h3 {
      color: ${({ theme }) => theme.colors.accent};
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
    }

    .project-counts {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      h3 {
        margin-bottom: 0;
      }

      .plus-upcoming {
        color: ${({ theme }) => theme.colors.accent}80;
        font-size: 1.5rem;
        font-weight: bold;
      }
    }

    .upcoming-note {
      display: block;
      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.accent}80;
      margin-top: 0.25rem;
    }

    p {
      color: ${({ theme }) => theme.colors.textSecondary};
      font-size: 1rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`; 