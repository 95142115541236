import styled from 'styled-components';
import { Container } from '../shared/StyledComponents';

export const HeroSection = styled.section`
  min-height: 90vh;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  padding: 2rem 0;
  
  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
      text-align: center;
      gap: 2rem;
    }
  }
`;

export const HeroContent = styled.div`
  flex: 1;

  h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    line-height: 1.1;
    
    .highlight {
      color: ${({ theme }) => theme.colors.accent};
      display: block;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      font-size: 2.5rem;
    }
  }

  p {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-bottom: 0.5rem;

    &.subtitle {
      font-size: 1.1rem;
      margin-bottom: 2rem;
    }
  }
`;

export const HeroImage = styled.div`
  flex: 1;
  max-width: 500px;
  aspect-ratio: 1;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  background: ${({ theme }) => theme.colors.secondary};
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 2rem;
    border-radius: 15px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    max-width: 400px;
  }
`;

export const CTAButton = styled.button`
  background: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.primary};
  padding: 1rem 2rem;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  box-shadow: 0 5px 15px ${({ theme }) => theme.colors.accent}40;
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 1.5rem;
  margin: 2rem 0;

  a {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: 1.5rem;
    transition: ${({ theme }) => theme.transitions.standard};

    &:hover {
      color: ${({ theme }) => theme.colors.accent};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: center;
  }
`; 