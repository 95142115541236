export const theme = {
  colors: {
    primary: '#000000',
    secondary: '#1A1A1A',
    accent: '#00ff9d',
    text: '#FFFFFF',
    textSecondary: '#AAAAAA',
  },
  breakpoints: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
  },
  transitions: {
    standard: '0.3s ease-in-out',
  }
}; 