import { motion } from 'framer-motion';
import { Container, SectionTitle } from '../shared/StyledComponents';
import { SkillsSection, SkillsContainer, SkillCategory, SkillsList } from './Skills.styles';
import { 
  FaReact, 
  FaNodeJs, 
  FaPython, 
  FaPhp, 
  FaDocker, 
  FaGitAlt,
  FaHtml5,
  FaCss3Alt,
  FaCat
} from 'react-icons/fa';
import { 
  SiTypescript, 
  SiJavascript, 
  SiVuedotjs, 
  SiTailwindcss, 
  SiBootstrap,
  SiExpo,
  SiStripe,
  SiPostgresql,
  SiMongodb,
  SiMysql,
  SiLaravel,
  SiVite
} from 'react-icons/si';

const Skills = () => {
  return (
    <SkillsSection id="skills">
      <Container>
        <SectionTitle
          as={motion.h2}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          Skills
        </SectionTitle>
        <SkillsContainer>
          <SkillCategory>
            <h4>Programming Languages</h4>
            <SkillsList>
              <li><SiTypescript /><span>TypeScript</span></li>
              <li><SiJavascript /><span>JavaScript</span></li>
              <li><FaPython /><span>Python</span></li>
              <li><FaPhp /><span>PHP</span></li>
            </SkillsList>
          </SkillCategory>

          <SkillCategory>
            <h4>Frontend Development</h4>
            <SkillsList>
              <li><FaReact /><span>React</span></li>
              <li><FaReact /><span>React Native</span></li>
              <li><SiVuedotjs /><span>Vue.js</span></li>
              <li><FaHtml5 /><span>HTML</span></li>
              <li><FaCss3Alt /><span>CSS/SCSS</span></li>
              <li><SiTailwindcss /><span>Tailwind</span></li>
              <li><SiBootstrap /><span>Bootstrap</span></li>
            </SkillsList>
          </SkillCategory>

          <SkillCategory>
            <h4>Backend Development</h4>
            <SkillsList>
              <li><FaNodeJs /><span>Node.js</span></li>
              <li><FaNodeJs /><span>Express.js</span></li>
              <li><SiLaravel /><span>Laravel</span></li>
            </SkillsList>
          </SkillCategory>

          <SkillCategory>
            <h4>Database Management</h4>
            <SkillsList>
              <li><SiMysql /><span>MySQL</span></li>
              <li><SiPostgresql /><span>PostgreSQL</span></li>
              <li><SiMongodb /><span>MongoDB</span></li>
            </SkillsList>
          </SkillCategory>

          <SkillCategory>
            <h4>Tools & Platforms</h4>
            <SkillsList>
              <li><FaDocker /><span>Docker</span></li>
              <li><FaGitAlt /><span>Git</span></li>
              <li><SiVite /><span>Vite</span></li>
              <li><SiExpo /><span>Expo</span></li>
            </SkillsList>
          </SkillCategory>

          <SkillCategory>
            <h4>Third-Party Integrations</h4>
            <SkillsList>
              <li><SiStripe /><span>Stripe</span></li>
              <li><FaCat /><span>RevenueCat</span></li>
            </SkillsList>
          </SkillCategory>
        </SkillsContainer>
      </Container>
    </SkillsSection>
  );
};

export default Skills; 